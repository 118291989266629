import * as angular from 'angular';
import * as $ from "jquery";

'use strict';

angular.module('complianceCtrl')
    .controller('complianceController', ComplianceController);

ComplianceController.$inject = ['$scope', 'compliance', 'scanStation', 'Auth', '$state',
    '$templateCache', '$window', '$interval', '$filter', '$location', 'Configuration'];

function ComplianceController($scope, compliance, scanStation, Auth, $state,
                              $templateCache, $window, $interval, $filter, $location, Configuration) {

    let vm = this;
    let version = Configuration.getVersion();
    const randomValue = Configuration.getRandomValue();

    vm.$onInit = async function () {
        try {
            vm.questionID = $state.params.questionID;
            vm.moduleID = $state.params.moduleID;
            vm.stateParams = $state.params;
            vm.reportDownloadUrl = "";
            vm.updateFieldOptionsURL = 'modules/compliance/update-field-options.html?v=' + version + 'rand=' + randomValue;

            if (typeof ($state.params) != 'undefined' && typeof ($state.params.uniqueModuleSlug) != 'undefined') {
                vm.moduleSlug = $state.params.uniqueModuleSlug;
                await vm.getModuleIDFromSlug();
            }

            if (compliance.getLinkedJob()) {
                vm.linkedJobSlug = compliance.getLinkedJob();
            }

            vm.dataToAdd = {};
            vm.filesToAdd = {};
            vm.prompt = false;
            vm.yellowPrompt = false;
            vm.focusFormFirst = false;
            vm.focusFormSecond = false;
            vm.submitForm = false;
            vm.preventFormSubmission = false;
            vm.focusWhere = '';
            vm.error = false;
            vm.showUndo = false;
            vm.updating = false;
            vm.takePhotoStatus = 'closed';
            vm.stateName = $state.current.name;
            vm.sectionReview = false;

            if (typeof ($state.params) != 'undefined' && typeof ($state.params.currentQuestionSlug) != 'undefined') {

                vm.currentQuestionSlug = $state.params.currentQuestionSlug;
                vm.checkSummaryReviewPage(vm.currentQuestionSlug);
            }

            vm.questionFields = [];
            vm.fieldsToDelete = [];
            vm.fieldsToUpdate = [];
            vm.previewQuestionAnswer = 'Yes';


        } catch (e) {
            console.log(e);
        }

    }

    vm.getModuleIDFromSlug = async function () {
        try {
            let data = await compliance.getModuleIDFromSlug(vm.moduleSlug);
            vm.moduleID = data.data.data;

        } catch (e) {
            console.log(e);
        }

    };

    vm.checkSummaryReviewPage = function (questionSlug) {
        try {
            let last15 = questionSlug.substr(questionSlug.length - 15);
            vm.sectionReview = last15 == '-summary-review';

        } catch (e) {
            console.log(e);
        }
    }

    vm.updateQuestion = async function () {
        try {
            vm.updating = true;
            await compliance.updateQuestion(vm.moduleID, vm.questionFields, vm.fieldsToDelete, vm.questionID);
            vm.updating = false;
            vm.fieldsToDelete = [];
            await vm.getQuestionData();

        } catch (e) {
            console.log(e);
            vm.updating = false;
            vm.error = e.data !== null ? e.data.error : e;
            alert('An error has occurred, please check browser console to read error.');
        }

    }

    vm.addQuestionField = async function () {
        try {
            if (typeof (vm.questionFields.name) == 'undefined') {
                alert('Please Enter a field name before continuing');
            } else {
                vm.fieldOrder = vm.questionFields.length + 1;
                vm.questionFields = vm.questionFields.concat([{
                    name: vm.questionFields.name,
                    field_data: vm.questionFields.field_data,
                    order_id: vm.fieldOrder,
                    field_type: vm.questionFields.field_type,
                    recommended_character_length: vm.questionFields.recommended_character_length
                }]);

                vm.questionFields = $filter('orderBy')(vm.questionFields, 'order_id', false);
                await vm.updateQuestion();
            }
        } catch (e) {
            console.log(e);
        }

    };

    vm.reOrderFields = async function (direction, index, currentOrderValue) {
        try {
            if (direction == 'up') {

                // cant move further up if index is 0
                if (currentOrderValue != 1) {
                    vm.questionFields[index - 1].order_id = parseInt(vm.questionFields[index - 1].order_id) + 1;
                    vm.questionFields[index].order_id = parseInt(vm.questionFields[index].order_id) - 1;
                    vm.questionFields = $filter('orderBy')(vm.questionFields, 'order_id', false);
                }
            }

            if (direction == 'down') {

                // cant move further down if is last one
                vm.fieldsLength = vm.questionFields.length;

                if (currentOrderValue != vm.fieldsLength) {
                    vm.questionFields[index + 1].order_id = parseInt(vm.questionFields[index + 1].order_id) - 1;
                    vm.questionFields[index].order_id = parseInt(vm.questionFields[index].order_id) + 1;
                    vm.questionFields = $filter('orderBy')(vm.questionFields, 'order_id', false);
                }
            }

            await vm.updateQuestion();

        } catch (e) {
            console.log(e);
        }

    }

    vm.addToFieldsToDelete = async function (slug, index) {
        try {
            vm.questionFields.splice(index, 1);

            vm.questionFields = $filter('orderBy')(vm.questionFields, 'order_id', false);
            vm.fieldsToDelete = vm.fieldsToDelete.concat([
                {slug: slug}
            ]);

            await vm.updateQuestion();

        } catch (e) {
            console.log(e);
        }

    }

    vm.getQuestionData = async function () {
        try {
            // TODO figure out why this function is being called before this value is set, current code is a workaround
            if (typeof vm.currentQuestionSlug == 'undefined') {
                vm.currentQuestionSlug = $state.params.currentQuestionSlug;
            }

            let data = await compliance.getQuestionData(vm.questionID, vm.currentQuestionSlug, vm.moduleSlug, vm.moduleID);

            vm.questionFields = data.data.questionFields;
            vm.questionFieldSlugs = [];
            vm.questionFieldTypes = [];

            // string data getting mixed with int data
            angular.forEach(vm.questionFields, async function (value, index) {

                vm.questionFields[index].order_id = parseInt(value.order_id);

                // get the field slugs so we can generate a sample report
                vm.questionFieldSlugs.push(value.slug);

                // field types for use in the resulting data to determine how to present it
                vm.questionFieldTypes[value.slug] = value.field_type;

                if (value.field_type == 'sample-file') {
                    value.fieldFileType = value.field_meta_data.fileName.substr(value.field_meta_data.fileName.length - 3);
                }

                if (value.field_type == 'multi-button' || value.field_type == 'large-data-entry' ||
                    value.field_type == 'small-data-entry') {

                    if (value.field_type == 'multi-button') {
                        vm.questionFields[index].partiallyCompleteValue = value.field_meta_data.partiallyCompleteValue;
                        vm.questionFields[index].fullyCompleteValue = value.field_meta_data.fullyCompleteValue;
                    }


                    if (value.field_meta_data !== null && typeof (value.field_meta_data.multiButtonBlocworxLinks)
                        != 'undefined' && value.field_meta_data.multiButtonBlocworxLinks != null) {

                        let multiButtonBlocworxLinks = value.field_meta_data.multiButtonBlocworxLinks.split(',');

                        value.multiButtonBlocworxLinksArray = [];

                        angular.forEach(multiButtonBlocworxLinks, async function (multiButtonBlocworxLinksValue) {
                            let multiButtonBlocworxLinksValueArray = multiButtonBlocworxLinksValue.split(':');
                            // $timeout(function () {
                            let data = await scanStation.getIndependantStationJobIDandStationID(multiButtonBlocworxLinksValueArray[1]);
                            let jobSlugData = await scanStation.getStaticJobSlug(data.data.jobID);
                            value.multiButtonBlocworxLinksArray.push([multiButtonBlocworxLinksValueArray[0], "/module/"
                            + jobSlugData.data.staticJobSlug + '/form/' + data.data.stationID]);
                            // }, 3000);
                        });
                    }
                }

                if (value.field_type == 'link-to-blocworx-form') {

                    let data = await scanStation.getIndependantStationJobIDandStationID(value.field_meta_data.blocworxFormSlug);
                    let jobSlugData = await scanStation.getStaticJobSlug(data.data.jobID);
                    value.blocworxFormUrl = "/module/" + jobSlugData.data.staticJobSlug + '/form/' + data.data.stationID;
                }

            });


            // to send as get request
            vm.questionFieldSlugs = angular.toJson(vm.questionFieldSlugs);
            vm.questionFields = $filter('orderBy')(vm.questionFields, 'order_id', false);
            vm.question = data.data.question;
            vm.questionSettings = data.data.questionSettings;

            if (typeof (vm.questionSettings.right_tab) == 'undefined') {
                vm.questionSettings.right_tab = 'No, More Details »';
            }

            if (typeof (vm.questionSettings.left_tab) == 'undefined') {
                vm.questionSettings.left_tab = 'Yes';
            }

            vm.sections = data.data.sections;
            vm.sectionID = data.data.sectionID;
            vm.sectionName = data.data.sectionName;
            vm.sectionSlug = data.data.sectionSlug;
            vm.canDisplay = data.data.canDisplay;
            vm.navigationDetails = data.data.navigationDetails;

            if (vm.stateName === 'o-module') {
                await vm.getGraphData();
            }

        } catch (e) {
            console.log(e);
        }

    };

    vm.updateQuestionSetting = async function (settingKey, questionID, settingValue) {
        try {
            await compliance.updateQuestionSetting(settingKey, questionID, settingValue);
        } catch (e) {
            console.log(e);
            // TODO: Adrian why we have an alert for this action?
            alert('Error:' + JSON.stringify(e));
        }

    }

    vm.triggerPrompt = async function (index) {
        try {
            // $timeout(function () {
            $('#question-field-' + index).val('');
            $('#question-field-' + index).focus();
            $('#question-field-' + index).addClass('rule-broken');

            await vm.playSound('error');
            // }, 0);

        } catch (e) {
            console.log(e);
        }

    }

    vm.triggerYellowPrompt = async function (index) {
        try {
            // $timeout(function () {
            $('#question-field-' + index).focus();
            $('#question-field-' + index).addClass('rule-broken');
            await vm.playSound('warning');
            // }, 0);

        } catch (e) {
            console.log(e);
        }

    }

    vm.playSound = async function (sound) {
        try {
            let snd = new Audio();
            if (sound == 'error') {
                snd = new Audio("/resources/error.mp3");
            }

            if (sound == 'warning') {
                snd = new Audio("/resources/warning-sound.mp3");
            }
            await snd.play();

        } catch (e) {
            console.log(e);
        }

    }

    vm.filterStatus = function (item) {
        try {
            return item === 'red' || item === 'blue';
        } catch (e) {
            console.log(e);
        }

    }

    vm.getData = async function (questionID) {
        try {
            let data = await compliance.getData(questionID);
            if (typeof (data) != 'undefined') {
                vm.liveData = data.data.data;
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.getQuestionUsers = async function (questionID) {
        try {
            let data = await compliance.getQuestionUsers(questionID);
            vm.questionUsers = data.data.data;

        } catch (e) {
            console.log(e);
        }

    }

    vm.addQuestionUser = async function (moduleID, userID) {
        try {
            vm.updating = true;
            await compliance.addQuestionUser(vm.questionID, moduleID, userID);
            await vm.getQuestionUsers(vm.questionID);
            vm.updating = false;

        } catch (e) {
            console.log(e);
            vm.updating = false;
            if (typeof (e.data.error) != 'undefined') {
                vm.prompt = e.data.error;
            } else {
                vm.prompt = 'Error: Unknown';
            }

            // TODO: Another alert with error for user to see it, we should move this to an alert module, so only admins can see
            alert('Error, please check browser console to read error.');
        }

    }

    vm.deleteUserFromQuestion = async function (questionUserID) {
        try {
            vm.updating = true;
            await compliance.deleteUserFromQuestion(questionUserID);
            await vm.getQuestionUsers(vm.questionID);
            vm.updating = false;

        } catch (e) {
            console.log(e);
            vm.updating = false;

            // TODO: Another alert with error for user to see it, we should move this to an alert module, so only admins can see
            alert('Error:' + JSON.stringify(e));
        }

    }

    vm.getSections = async function () {
        try {
            vm.updating = true;
            let data = await compliance.getSections(vm.moduleID, vm.moduleSlug);
            vm.sections = data.data.data;

        } catch (e) {
            console.log(e);
        }

    }

    vm.scrollTop = function () {
        try {
            $('html, body').animate({
                scrollTop: "0px"
            }, 800);
        } catch (e) {
            console.log(e);
        }

    }

    vm.openModuleFile = async function (moduleID, fileName) {
        try {
            vm.loadingReport = true;
            let data = await compliance.openModuleFile(moduleID, vm.moduleSlug, fileName);
            vm.loadingReport = false;
            await vm.openFileLocally(fileName, data.data);

        } catch (e) {
            console.log(e);
        }

    }

    vm.openUserFile = async function (fieldID, fileName) {
        try {
            vm.loadingReport = true;
            let data = await compliance.openUserFile(fieldID, fileName);
            vm.loadingReport = false;
            await vm.openFileLocally(fileName, data.data);

        } catch (e) {
            console.log(e);
        }

    }

    vm.openFileLocally = async function (fileName, data) {
        try {
            let ext = fileName.substr(fileName.lastIndexOf('.') + 1);


                let url = $window.URL || $window.webkitURL;
                vm.fileUrl = url.createObjectURL(data);
                let a = document.createElement("a");
                a.href = vm.fileUrl;
                a.download = fileName;
                a.setAttribute('target', '_blank');
                document.body.appendChild(a);
                a.click();


        } catch (e) {
            console.log(e);
        }

    }

    vm.createModule = async function () {
        try {
            await compliance.createModule(vm.createModuleData)
            $state.go('o-admin-modules');

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateModule = async function () {
        try {
            if (typeof (vm.moduleData.unit_rate) == 'undefined' || typeof (vm.moduleData.quantity) == 'undefined') {
                alert('Please make sure the Unit Rate and Quantity are numbers');
            } else {
                await compliance.updateModule(vm.moduleData, vm.moduleID);
                await vm.getModule();

                alert('Module Successfully Updated');
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.getUsersModules = async function () {
        try {
            let data = await compliance.getUsersModules();
            vm.moduleList = data.data.data;

        } catch (e) {
            console.log(e);
        }

    }

    vm.getModules = async function (status) {
        try {
            let data = await compliance.getModules(status);
            vm.moduleList = data.data.data;

        } catch (e) {
            console.log(e);
        }

    }

    vm.getModule = async function () {
        try {
            let data = await compliance.getModule(vm.moduleID, vm.moduleSlug);
            vm.moduleData = data.data.data;

        } catch (e) {
            console.log(e);
        }

    }

    vm.archiveModule = async function () {
        try {
            await compliance.archiveModule(vm.moduleID);
            $window.location.href = '/admin/closed-modules';
        } catch (e) {
            console.log(e);
        }

    }

    vm.updateSectionStatus = async function (question, status) {
        try {
            await compliance.updateSectionStatus(vm.moduleID, question, status);
            $window.location.href = '/admin/module/' + vm.moduleID;

        } catch (e) {
            console.log(e);
        }

    }

    vm.getQuestions = async function () {
        try {
            vm.unassigedQuestions = false;
            let data = await compliance.getQuestions(vm.moduleID, vm.moduleSlug);
            vm.questions = data.data.data;

            angular.forEach(vm.questions, function (value) {
                if (value.section_id == null) {
                    vm.unassigedQuestions = true;
                }
            });

            vm.sections = data.data.sections;

        } catch (e) {
            console.log(e);
        }

    }

    vm.addNewQuestion = async function () {
        try {
            await compliance.addNewQuestion(vm.moduleID, vm.question, vm.newSectionID);
            await vm.getQuestions();
            vm.newSectionID = null;

        } catch (e) {
            console.log(e);
            if (typeof (e.data.error) != 'undefined') {
                vm.prompt = e.data.error;
            } else {
                vm.prompt = 'Error perhaps some of your data is not entered?';
            }
        }

    }

    vm.addNewSection = async function () {
        try {
            await compliance.addNewSection(vm.moduleID, vm.newSectionName);
            await vm.getQuestions();
        } catch (e) {
            console.log(e);
            if (typeof (e.data.error) != 'undefined') {
                vm.prompt = e.data.error;
            } else {
                vm.prompt = 'Error perhaps some of your data is not entered?';
            }
        }

    }

    vm.updateQuestionOrderID = async function (questionID, direction) {
        try {
            await compliance.updateQuestionOrderID(questionID, direction);
            await vm.getQuestions();

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateSectionOrderID = async function (questionID, direction) {
        try {
            await compliance.updateSectionOrderID(questionID, vm.moduleID, direction);
            await vm.getQuestions();

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateSectionName = async function (sectionID, sectionName) {
        try {
            await compliance.updateSectionName(sectionID, sectionName);

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateQuestionName = async function (questionID, questionName) {
        try {
            await compliance.updateQuestionName(questionID, questionName);
        } catch (e) {
            console.log(e);
        }

    }

    vm.updateField = async function (fieldID, updateType, value) {
        try {
            await compliance.updateField(fieldID, updateType, value);
        } catch (e) {
            console.log(e);
        }

    }

    vm.deleteQuestion = async function (questionID) {
        try {
            await compliance.deleteQuestion(questionID);
            await vm.getQuestions();
            alert('Question deleted');

        } catch (e) {
            console.log(e);
            alert('Error');
        }

    }

    vm.deleteSection = async function (sectionID) {
        try {
            await compliance.deleteSection(sectionID);
            await vm.getQuestions();
            alert('Section deleted');

        } catch (e) {
            console.log(e);
            alert('Error');
        }

    }

    vm.deleteQuestion = async function (questionID) {
        try {
            await compliance.deleteQuestion(questionID);
            await vm.getQuestions();
            alert('Question deleted');

        } catch (e) {
            console.log(e);
            alert('Error');
        }

    }

    vm.uploadModuleFile = async function () {
        try {
            let file = vm.moduleFile;
            vm.pleasewait = true;

            await compliance.uploadModuleFile(vm.moduleFileTitle, file, vm.moduleID);
            await vm.getUploadedModuleFiles();
            alert('File uploaded');
            vm.pleasewait = false;

        } catch (e) {
            console.log(e);

            // TODO: Adrian this is another place for the errors log only for admins
            alert('Error Uploading');
            vm.pleasewait = false;
        }

    }

    vm.deleteModuleFile = async function (id) {
        try {
            await compliance.deleteModuleFile(id);
            await vm.getUploadedModuleFiles();
            alert('File deleted');

        } catch (e) {
            console.log(e);
            alert('Error deleting file');
        }

    }

    vm.getUploadedModuleFiles = async function () {
        try {
            let data = await compliance.getUploadedModuleFiles(vm.moduleID);
            vm.uploadedModuleFiles = data.data.data;

        } catch (e) {
            console.log(e);
        }

    }

    vm.uploadUserFile = async function (fieldSlug, fieldID) {
        try {
            let file = vm.questionFieldAnswers[fieldSlug].file;
            vm.pleasewait = true;

            await compliance.uploadUserFile(fieldID, file);
            vm.questionFieldAnswers[fieldSlug].userFieldValue = file.name;
            vm.questionFieldAnswers[fieldSlug].fieldID = fieldID;
            await vm.saveAnswers(vm.currentQuestionSlug, vm.moduleSlug, false);
            vm.pleasewait = false;

        } catch (e) {
            console.log(e);
            vm.pleasewait = false;
        }

    }

    vm.getUsersFilesForQuestion = async function () {
        try {
            let data = await compliance.getUsersFilesForQuestion(vm.currentQuestionSlug, vm.moduleSlug);
            vm.userUploadedFiles = data.data.data;

        } catch (e) {
            console.log(e);
        }

    }

    vm.deleteUserFile = async function (fieldSlug, fileName, fieldID) {
        try {
            vm.questionFieldAnswers[fieldSlug] = null;
            await compliance.deleteUserFile(fileName, fieldID);
            await vm.saveAnswers(vm.currentQuestionSlug, vm.moduleSlug, false);

        } catch (e) {
            console.log(e);
            // TODO: Adrian this is another place for the errors log only for admins
            alert('Error deleting file');
        }

    }

    vm.textFieldsFilter = function (field) {
        try {
            return field.field_type == 'large-data-entry' || field.field_type == 'small-data-entry';
        } catch (e) {
            console.log(e);
        }
    }

    vm.multiButtonFilter = function (field) {
        try {
            return field.field_type == 'multi-button';
        } catch (e) {
            console.log(e);
        }
    }

    vm.blocworxFormLinksFilter = function (field) {
        try {
            return field.field_type == 'link-to-blocworx-form';
        } catch (e) {
            console.log(e);
        }
    }

    vm.fileUploadFilter = function (field) {
        try {
            return field.field_type == 'file-upload';
        } catch (e) {
            console.log(e);
        }
    }

    vm.fileUploadFilter = function (field) {
        try {
            return field.field_type == 'file-upload';
        } catch (e) {
            console.log(e);
        }
    }

    vm.mainExplainerTextFilter = function (field) {
        try {
            return field.field_type == 'main-explainer-text';
        } catch (e) {
            console.log(e);
        }
    }

    vm.questionExplainerTextFilter = function (field) {
        try {
            return field.field_type == 'question-explainer-text';
        } catch (e) {
            console.log(e);
        }
    }

    vm.questionExplainerTextFilter = function (field) {
        try {
            return field.field_type == 'question-explainer-text';
        } catch (e) {
            console.log(e);
        }
    }

    vm.sameAnswerFilter = function (field) {
        try {
            return field.field_type == 'sample-answer-text';
        } catch (e) {
            console.log(e);
        }
    }

    vm.furtherReadingFilter = function (field) {
        try {
            return field.field_type == 'further-link';
        } catch (e) {
            console.log(e);
        }
    }

    vm.sampleFileFilter = function (field) {
        try {
            return field.field_type == 'sample-file';
        } catch (e) {
            console.log(e);
        }
    }

    vm.getTasksBySectionID = async function () {
        try {
            let data = await compliance.getTasksBySectionID(vm.sectionID, vm.moduleSlug, vm.currentQuestionSlug);
            vm.sectionTasksRemaining = data.data.data;
            vm.sectionName = data.data.sectionName;

        } catch (e) {
            console.log(e);
        }

    }

    vm.getReviewPageNavigation = async function () {
        try {
            let data = await compliance.getReviewPageNavigation(vm.sectionID, vm.currentQuestionSlug, vm.moduleSlug);
            vm.reviewNavigation = data.data.data;

        } catch (e) {
            console.log(e);
        }

    }

    vm.goToQuestion = async function (questionSlug, moduleSlug) {
        try {
            // TODO: Adrian this is another place for the errors log only for admins
            console.log(questionSlug);
            console.log(moduleSlug);

            vm.previewQuestionAnswer = 'not-answered';
            vm.yesNoSelected = 'not-answered';
            vm.checkSummaryReviewPage(questionSlug);
            await vm.saveAnswers(questionSlug, moduleSlug, true);

        } catch (e) {
            console.log(e);
        }

    }

    vm.saveAnswers = async function (questionSlug, moduleSlug, newSlide) {
        try {
            vm.saving = true;
            angular.forEach(vm.questionFieldAnswers, function (value) {
                if (value != null) {
                    if (typeof (value.buttonAnswer) != 'undefined') {
                        if (typeof (value.userFieldValue) != 'undefined') {
                            value.userFieldValue = value.buttonAnswer + '[BW]' + value.userFieldValue;
                        } else {
                            value.userFieldValue = value.buttonAnswer + '[BW]';
                        }
                    }
                }
            });

            await compliance.saveAnswers(vm.currentQuestionSlug, vm.moduleID, vm.questionFieldAnswers);

            vm.saving = false;
            vm.currentQuestionSlug = questionSlug;

            if (vm.sectionReview == false) {
                await vm.getQuestionData();
            } else {
                vm.sectionID = undefined;
                await vm.getReviewPageNavigation();
                await vm.getTasksBySectionID();
            }

            if (newSlide === true) {

                $state.transitionTo('o-module', {
                    currentQuestionSlug: questionSlug,
                    uniqueModuleSlug: moduleSlug
                }, {
                    location: true,
                    inherit: true,
                    relative: $state.$current,
                    notify: false
                });
                vm.scrollTop();
            }
            await vm.getAnswers();

        } catch (e) {
            vm.saving = false;
            console.log(e);
        }

    }

    vm.getAnswers = async function () {
        try {
            let data = await compliance.getAnswers(vm.currentQuestionSlug, vm.moduleID, vm.moduleSlug);
            vm.questionFieldAnswers = {};

            angular.forEach(data.data.data, function (value) {

                vm.questionFieldAnswers[value.slug] = {
                    fieldID: value.compliance_question_field_id,
                    userFieldValue: value.user_field_value,
                    characterCount: value.user_field_value.length,
                    buttonAnswer: value.button_answer,
                    fieldFileType: value.user_field_value.substr(value.user_field_value.length - 3)
                };

                if (value.field_type == 'multi-button') {

                    let partiallyCompleteValue = value.field_meta_data.partiallyCompleteValue.split(',');
                    let fullyCompleteValue = value.field_meta_data.fullyCompleteValue.split(',');
                    vm.questionFieldAnswers[value.slug].characterCount = 0;


                    angular.forEach(partiallyCompleteValue, function (pcValue) {
                        if (vm.questionFieldAnswers[value.slug].buttonAnswer == pcValue) {
                            vm.questionFieldAnswers[value.slug].characterCount = 5;
                        }
                    });

                    angular.forEach(fullyCompleteValue, function (fcValue) {
                        if (vm.questionFieldAnswers[value.slug].buttonAnswer == fcValue) {
                            vm.questionFieldAnswers[value.slug].characterCount = 10;
                        }
                    });
                }
            });
            await vm.getGraphData();

        } catch (e) {
            console.log(e);
        }

    }

    vm.determineCharacterCountStatus = function (characterCount, recommendedCount) {
        try {
            if (characterCount == 0 || typeof (characterCount) == 'undefined') {
                return 'incomplete';
            } else if (characterCount > 0 && characterCount <= recommendedCount - 1) {
                return 'partially-complete';
            } else {
                return 'complete';
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.determineButtonCharacterCountStatus = function (characterCount, fieldSlug) {
        try {
            if (typeof(vm.questionFieldAnswers) != 'undefined' && typeof(vm.questionFieldAnswers[fieldSlug]) != 'undefined') {
                characterCount = vm.questionFieldAnswers[fieldSlug].characterCount;

                if (characterCount == 0 || typeof (characterCount) == 'undefined') {
                    return 'incomplete';
                } else if (characterCount > 0 && characterCount <= 5) {
                    return 'partially-complete';
                } else {
                    return 'complete';
                }
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.getGraphData = async function () {
        try {
            vm.showGraph = false;
            let data = await compliance.getGraphData(vm.sectionID, vm.moduleSlug, vm.currentQuestionSlug);

            let complete = data.data.data.complete;
            let partiallyComplete = data.data.data.partiallyComplete;
            let incomplete = data.data.data.incomplete;
            vm.percentageComplete = data.data.data.percentageComplete;
            vm.showGraph = true;

            const Chart = await import('chart.js');

            // $timeout(function () {
            let config = {
                "type": "doughnut",
                "data": {
                    "labels": ["Complete", "Partially Complete", "Incomplete"],
                    "datasets": [
                        {
                            "label": "Section Progress",
                            "data": [complete, partiallyComplete, incomplete],
                            "backgroundColor": ["rgb(68,162,50)", "rgb(224,173,52)", "rgb(255, 99, 132)"]
                        }
                    ]
                },
                options: {
                    segmentShowStroke: false,
                    cutoutPercentage: 60,
                    legend: {
                        display: false
                    },
                    elements: {
                        arc: {
                            borderWidth: 0
                        }
                    }
                }
            };
            $scope.$apply();
            let pieChart = new Chart(document.getElementsByClassName(vm.sectionSlug)[0], config);
            pieChart.update();
            // }, 0);

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateChart = async function () {
        try {
            await vm.saveAnswers(vm.currentQuestionSlug, vm.moduleSlug, false);
        } catch (e) {
            console.log(e);
        }
    }

    vm.getUserAssessments = async function () {
        try {
            let data = await compliance.getUserAssessments();
            vm.userAssessments = data.data.data;

        } catch (e) {
            console.log(e);
        }

    }

    vm.submitAssessment = async function (newAssessmentData, instanceID) {
        try {
            await compliance.submitAssessment(newAssessmentData, instanceID);
            await vm.getUserAssessments();

        } catch (e) {
            console.log(e);
        }

    }

    vm.getModulesAssessments = async function () {
        try {
            let data = await compliance.getModulesAssessments();
            vm.modulesAssessments = data.data.data;

        } catch (e) {
            console.log(e);
        }

    }

    vm.getCompleteReview = async function () {
        try {
            vm.totalComplete = 0;
            vm.totalPartiallyComplete = 0;
            vm.totalIncomplete = 0;

            let data = await compliance.getSections(vm.moduleID, vm.moduleSlug);
            vm.allSections = data.data.data;
            vm.sectionDetails = {};

            angular.forEach(vm.allSections, async function (section) {

                vm.sectionDetails[section.slug] = [];
                vm.sectionDetails[section.slug].name = section.name;
                vm.sectionDetails[section.slug].canDisplay = section.canDisplay;

                // TODO: Adrian why we have false as an argument here?
                let tasksBySection = await compliance.getTasksBySectionID(section.id, vm.moduleSlug, false);
                vm.sectionDetails[section.slug].sectionTasksRemaining = tasksBySection.data.data;

                let graphData = await compliance.getGraphData(section.id, vm.moduleSlug, section.slug + '-summary-review');


                vm.sectionDetails[section.slug].complete = graphData.data.data.complete;
                vm.totalComplete = vm.totalComplete + vm.sectionDetails[section.slug].complete;
                vm.sectionDetails[section.slug].partiallyComplete = graphData.data.data.partiallyComplete;
                vm.totalPartiallyComplete = vm.totalPartiallyComplete + vm.sectionDetails[section.slug].partiallyComplete;
                vm.sectionDetails[section.slug].incomplete = graphData.data.data.incomplete;
                vm.totalIncomplete = vm.totalIncomplete + vm.sectionDetails[section.slug].incomplete;
                vm.sectionDetails[section.slug].percentageComplete = graphData.data.data.percentageComplete;


                const Chart = await import('chart.js');

                // $timeout(function () {
                let configFileChart = {
                    "type": "doughnut",
                    "data": {
                        "labels": ["Complete", "Partially Complete", "Incomplete"],
                        "datasets": [
                            {
                                "label": "Section Progress",
                                "data": [
                                    vm.sectionDetails[section.slug].complete,
                                    vm.sectionDetails[section.slug].partiallyComplete,
                                    vm.sectionDetails[section.slug].incomplete
                                ],
                                "backgroundColor": ["rgb(68,162,50)", "rgb(224,173,52)", "rgb(255, 99, 132)"]
                            }
                        ]
                    },
                    options: {
                        segmentShowStroke: false,
                        cutoutPercentage: 60,
                        legend: {
                            display: false
                        },
                        elements: {
                            arc: {
                                borderWidth: 0
                            }
                        }
                    }
                };

                new Chart(document.getElementsByClassName(section.slug + '-summary-review')[0], configFileChart);
                vm.totalQuestions = vm.totalComplete + vm.totalIncomplete + vm.totalPartiallyComplete;
                vm.totalCompletePerc = (vm.totalComplete / vm.totalQuestions) * 100;
                vm.totalIncompleteCompletePerc = (vm.totalIncomplete / vm.totalQuestions) * 100;
                vm.totalPartiallyCompletePerc = (vm.totalPartiallyComplete / vm.totalQuestions) * 100;
                vm.totalCompletePercText = Math.round(vm.totalCompletePerc);
                // });
            });

        } catch (e) {
            console.log(e);
        }

    }

    vm.exportToPdf = async function () {
        try {
            vm.saving = true;
            vm.reportDownloadUrl = "";
            let canvasArray = [];

            // Transform canvases
            $(".chartjs-render-monitor").each(function (index, canvas: HTMLCanvasElement) {
                canvasArray.push(canvas.toDataURL());
            });

            let response = await compliance.chartReport(vm.moduleID, canvasArray);
            let blob = new Blob([response.data], {type: 'application/octet-stream'});
            let URL = window.URL || window.webkitURL;
            let downloadUrl = URL.createObjectURL(blob);
            vm.reportDownloadUrl = downloadUrl;


            let element = await vm.getElementToClick('downloadPdfBtn');

            $scope.$apply();
            element.click();

            vm.saving = false;
            $scope.$apply();


        } catch (e) {
            console.log(e);
        }

    }

    vm.getElementToClick = function(element) {
        return document.getElementById(element);
    }

    vm.initMultiButtonCharacterCount = function () {
        try {
            // TODO: this need to be fixed, as fieldSlug isn't being set
            let fieldSlug = null;

            angular.forEach(vm.questionFields, function (value) {

                let partiallyCompleteValue = value.partiallyCompleteValue.split(',');
                let fullyCompleteValue = value.fullyCompleteValue.split(',');
                vm.questionFieldAnswers[fieldSlug].characterCount = 0;

                angular.forEach(partiallyCompleteValue, function (value) {
                    if (vm.questionFieldAnswers[fieldSlug].buttonAnswer == value) {
                        vm.questionFieldAnswers[fieldSlug].characterCount = 5;
                    }
                });

                angular.forEach(fullyCompleteValue, function (value) {
                    if (vm.questionFieldAnswers[fieldSlug].buttonAnswer == value) {
                        vm.questionFieldAnswers[fieldSlug].characterCount = 10;
                    }
                });

            });

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateMultiButtonCharacterCount = function (fieldSlug) {
        try {
            angular.forEach(vm.questionFields, function (value) {

                if (value.slug == fieldSlug) {
                    let partiallyCompleteValue = value.partiallyCompleteValue.split(',');
                    let fullyCompleteValue = value.fullyCompleteValue.split(',');
                    vm.questionFieldAnswers[fieldSlug].characterCount = 0;

                    angular.forEach(partiallyCompleteValue, function (value) {
                        if (vm.questionFieldAnswers[fieldSlug].buttonAnswer == value) {
                            vm.questionFieldAnswers[fieldSlug].characterCount = 5;
                        }
                    });

                    angular.forEach(fullyCompleteValue, function (value) {
                        if (vm.questionFieldAnswers[fieldSlug].buttonAnswer == value) {
                            vm.questionFieldAnswers[fieldSlug].characterCount = 10;
                        }
                    });
                }
            });

        } catch (e) {
            console.log(e);
        }

    }

    vm.deleteModule = async function (moduleID) {
        try {
            let data = await compliance.deleteModule(moduleID);
            if (data.status == 200) {
                await vm.getModules();
            }

        } catch (e) {
            console.log(e);
        }

    }

}

